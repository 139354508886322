var lightGallery = require('lightgallery')
var lgVideo = require('lg-video')
var lgThumbnail = require('lg-thumbnail')

var ie = false
var ie7 = false
var ie8 = false

$(function () {
    var sameHeightSelectors = [
        { selector: '.sport-section-list-wrapper .sport-section-item' },
        { selector: '.stages-list-wrapper .stage-item' }
    ]

    function sameHeight (className, parent) {
        if ($(document).width() > 767) {
            parent = typeof (parent) !== 'undefined' && parent ? parent : 'body'

            $(parent).each(function () {
                var biggest = 0
                var elements = $(this).find(className)
                if (elements.length === 0) {
                    return
                }

                for (var i = 0; i < elements.length; i++) {
                    elements[i].style.height = ''
                    if (elements[i].offsetHeight > biggest) {
                        biggest = elements[i].offsetHeight
                    }
                }
                for (i = 0; i < elements.length; i++) {
                    elements[i].style.height = biggest + 'px'
                }
            })
        } else {
            parent = typeof (parent) !== 'undefined' && parent ? parent : 'body'

            $(parent).each(function () {
                var elements = $(this).find(className)
                if (elements.length === 0) {
                    return
                }
                for (i = 0; i < elements.length; i++) {
                    elements[i].style.height = 'auto'
                }
            })
        }
    }

    function identifyIE () {
        if (navigator.appVersion.indexOf('MSIE 7.0') !== -1) {
            ie7 = true
            $('body').addClass('ie7')
        }
        if (navigator.appVersion.indexOf('MSIE 8.0') !== -1) {
            ie8 = true
            $('body').addClass('ie8')
        }

        if (ie7 || ie8) { ie = true }

        if (ie) {
            $('body').addClass('ie')
        }
    }

    function initCollapse () {
        // The "open" class is placed on the surrounding div, but should really be on the heading
        $('.collapsible.open').each(function () {
            $(this).removeClass('open')
            $(this).find('.heading').removeClass('closed').addClass('open')
        })
        if ($('.accordion').length > 0) {
            $('.accordion').collapse({
                query: 'div .accordion-heading',
                accordion: true
            })
        }
        $('.collapsible').collapse()

        // Hack to prevent the animation from jumping at the end of the slideDown
        $('.accordion-body').each(function () {
            $(this).css({ width: $(this).css('width') })
        })
    }

    window.onresize = function () {
        sameHeightSelectors.forEach(function (item) {
            sameHeight(item.selector, item.parent)
        })
    }

    $(document).ready(function () {
        /**
         * Object representing the collapsible top bar containing tools like language switch,
         * search box, etc.
         *
         * @type {{topbar: null, init: Function, expand: Function, collapse: Function}}
         */
        var TopBar = {
            topbar: null,
            /**
             * Initializes top bar DOM object and relevant click events
             */
            init: function () {
                $('#topbar-icons > div').on('click', function (e) {
                    // Select the target item based on the "href" attribute of the link
                    var target = $($(this).find('a').attr('href'))
                    if (target.length > 0 && target.hasClass('topbar-item')) {
                        e.preventDefault()
                        var visible = target.is(':visible')
                        // Hide all opened items and remove active class from icons
                        $('.topbar-item').removeClass('visible-sm').removeClass('visible-xs')
                        $('.topbar-item').addClass('hidden-sm').addClass('hidden-xs')
                        $('#topbar-icons > div').removeClass('active')

                        if (!visible) {
                            $($(this).find('a').attr('href')).removeClass('hidden-sm').addClass('hidden-xs')
                            $($(this).find('a').attr('href')).addClass('visible-sm').addClass('visible-xs')
                            $(this).addClass('active')
                        } else {
                            $($(this).attr('href')).hide()
                            $(this).removeClass('active')
                        }
                    }
                })
            }
        }

        identifyIE()
        initCollapse()
        // This fixes modal boxes on IE7. They need to be on the same level as the grey backdrop.
        $('.modal').appendTo($('body'))

        // Activate colorbox on image groups
        if ($('.lightbox').length) {
            $('.lightbox').parents('.block').parent('.box').addClass('ltbx')
            $('.ltbx').lightGallery({
                selector: '.lightbox',
                controls: true,
                counter: true,
                thumbnail: true,
                fullScreen: true,
                download: false,
                appendCounterTo: '.lg-toolbar'
            })
        }

        if (typeof (TopBar) !== 'undefined') {
            TopBar.init()
        }
        if (typeof (FirstLast) !== 'undefined') {
            FirstLast.apply()
            FirstLast.cleanup()
        }

        // Load user login box
        if ($('#cw_box_user').length > 0 && typeof (PACKAGE) !== 'undefined') {
            cwidgets(
                PACKAGE.settings.contentWidgetsBaseUrl,
                'lib',
                'cw_box_user_complete',
                PACKAGE.settings.contentWidgetsPageType,
                'cw_box_user_content',
                ''
            )
        }

        if ($('#cw_lang_select').length > 0) {
            $('#cw_lang_select').on('change', function () {
                if ($(this).val().indexOf('http') == 0) {
                    document.location.href = $(this).val()
                }
            })
        }

        sameHeightSelectors.forEach(function (item) {
            sameHeight(item.selector, item.parent)
        })

        setTimeout(function () {
            sameHeightSelectors.forEach(function (item) {
                sameHeight(item.selector, item.parent)
            })
        }, 300)

        $('.photo-gallery').lightGallery({
            thumbnail: true,
            animateThumb: false,
            showThumbByDefault: true,
            selector: '.item',
            download: false
        })

        $('.photo-gallery-video').lightGallery({
            thumbnail: false,
            animateThumb: true,
            loadYoutubeThumbnail: true,
            autoplayFirstVideo: false,
            youtubeThumbSize: 'default',
            loadVimeoThumbnail: true,
            vimeoThumbSize: 'thumbnail_medium',
            showThumbByDefault: false,
            selector: '.item'
        })

        $('.open-inner-gallery-wrapper-video .open-inner-gallery').click(function (e) {
            var $firstChild = $(this).parent().find('.photo-gallery-video .item:first-child')
            $firstChild.trigger('click')
            e.preventDefault()
        })

        $('.photo-gallery-wrapper.open-inner-gallery-wrapper .open-inner-gallery').click(function (e) {
            var $firstChild = $(this).parent().find('.photo-gallery .item:first-child')
            $firstChild.trigger('click')
            e.preventDefault()
        })

        $('.collapsible-news a[data-toggle]').each(function () {
            if ($(this).parent().find('.news-content').height() < 110) {
                $(this).hide()
            }
        })

        $('.collapsible-news a[data-toggle]').click(function () {
            var toggleClass = $(this).data('toggle')
            var target = $(this).data('target')
            $(target).toggleClass(toggleClass)
        })

        $('.prevent-default').click(function (e) {
            e.preventDefault()
        })
    })
})
